import styled from '@emotion/styled'

export const StarsSkippingContainer = styled.div`
  position: relative;
  /* width: 100%;
  height: 100%; */
`;

export const StarItemContainer = styled.div`
  position: absolute;
  animation: ${(props) => `skipping${props.num}`} 1s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  bottom: 0;
  opacity: 0;
  left: ${props => props.startLeft}px;
  bottom: ${props => props.startBottom}px;
  @keyframes ${(props) => `skipping${props.num}`} {
    0% {
      /* left: ${props => props.startLeft}px; */
      opacity: 0;
    }
    30% {
      opacity: 0.8;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      left: ${props => props.endLeft}px;
      /* bottom: 400px; */
      bottom: ${props => props.moveHeight}px;
    }
  }
  img {
    width: ${props => props.size}px;
    height: auto;
  }
`;